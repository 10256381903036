*:focus-visible,*:focus{
    outline: unset!important;
    box-shadow: unset!important;
}
*:not(.flatpickr-input)[readonly]{
    background-color: rgba(216, 216, 216, 0.5)!important;
}

.btn:disabled {
    opacity: 0.7 !important;
    cursor: not-allowed;
}

.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #1E293B;
    color: white;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    border-radius: 2px;
    margin-bottom: 5px;
  }
  .ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}